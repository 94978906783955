import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    Select,
    Upload,
    message,
} from "antd";
import "../../style/testimonial.css";
import { FetchParticularTestimonial, UpdateTestimonial } from "../../service/api_services";
import { useAuth } from "../../authentication/context/authContext";
import { AiOutlineEdit } from "react-icons/ai";
import DefaultImg from "../../assest/icon/imageIcon.png"

function EditTestimonial({ testimonialId, ShowAllTestimonialList }) {
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState(new FormData());
    const [form] = Form.useForm();
    const [uploder, setUploader] = useState(null);
    const [testimonialData, setTestimonialData] = useState({});
    const { token } = useAuth();
    const mediaList = ["image", "video"];
    const [mediaType, setMediaType] = useState("image");

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const ShowParticularServiceList = async () => {
        try {
            const testimonial = await FetchParticularTestimonial(token, testimonialId);
            setTestimonialData(testimonial);

            form.setFieldsValue({
                mediaType: testimonial.testimonialMediaType,
                message: testimonial.message,
            });
        } catch (err) {
            message.error("Failed to fetch testimonial details.");
        }
    };

    useEffect(() => {
        if (testimonialId) {
            ShowParticularServiceList();
        }
    }, [testimonialId]);

    const onFinish = async (value) => {
        try {
            if (uploder && value.logoFile) {
                formData.append("testimonialMedia", value.logoFile.file);
            }

            formData.append("testimonialId", testimonialId);
            formData.append("message", value.message);
            formData.append("testimonialMediaType", mediaType.toUpperCase());

            await UpdateTestimonial(token, formData).then((res) => {
                if (res.status === 201) {
                    message.success(res.data.message);
                    setOpen(false);
                    setFormData(new FormData());
                    ShowAllTestimonialList();
                    setUploader(null);
                } else {
                    message.error(res.data.message);
                }
            });
        } catch (error) {
            message.error("Error while updating testimonial.");
        }
    };

    const handlerImage = (file) => {
        setUploader(file.fileList);
    };

    return (
        <div className="modal_section">
            <AiOutlineEdit className="delete_icon" onClick={showDrawer} />
            <Drawer
                title="Edit Testimonial"
                placement="right"
                width={400}
                onClose={onClose}
                open={open}
            >
                <div className="add_category_form">
                    <Form
                        form={form}
                        layout="vertical"
                        name="edit-testimonial"
                        onFinish={onFinish}
                    >
                        <Col md={24}>
                            <Form.Item
                                name="mediaType"
                                label="Media Type"
                                rules={[{ required: true, message: "Please select a media type" }]}
                            >
                                <Select
                                    placeholder="Select Media Type"
                                    onChange={(value) => setMediaType(value)}
                                >
                                    {mediaList.map((option) => (
                                        <Select.Option key={option} value={option}>
                                            {option.charAt(0).toUpperCase() + option.slice(1)}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col md={24}>
                            <Form.Item
                                label="Message"
                                name="message"
                                rules={[{ required: true, message: "Please enter testimonial message" }]}
                            >
                                <Input placeholder="Enter Testimonial Message" />
                            </Form.Item>
                        </Col>

                        {mediaType === "image" && (
                            <Col md={24}>
                                <Form.Item
                                    name="logoFile"
                                    label={uploder?.length !== 1 ? "Choose Image" : "Replace Image"}
                                >
                                    <Upload
                                        multiple={false}
                                        listType="picture-circle"
                                        beforeUpload={() => false}
                                        accept="image/*"
                                        onChange={handlerImage}
                                    >
                                        {uploder?.length !== 1 ? (
                                            <img
                                                src={
                                                    testimonialData?.testimonialMediaUrl || DefaultImg
                                                }
                                                alt="Testimonial"
                                                className="image_hold"
                                            />
                                        ) : null}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        )}

                        {mediaType === "video" && (
                            <Col md={24}>
                                <Form.Item
                                    name="videoFile"
                                    label={uploder?.length !== 1 ? "Choose Video" : "Replace Video"}
                                >
                                    <Upload
                                        multiple={false}
                                        beforeUpload={() => false}
                                        accept="video/*,.mkv" // Added .mkv to accept MKV files
                                        onChange={handlerImage}
                                    >
                                        {uploder?.length !== 1 ? (
                                            <div className="video-container">
                                                {testimonialData?.testimonialMediaUrl ? (
                                                    <video controls className="video_hold">
                                                        <source
                                                            src={testimonialData?.testimonialMediaUrl}
                                                            type="video/mp4"
                                                        />
                                                    </video>
                                                ) : (
                                                    <img
                                                        src={DefaultImg}
                                                        alt="Default Placeholder"
                                                        className="image_hold"
                                                    />
                                                )}
                                            </div>
                                        ) : null}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        )}

                        <div className="model_Submit">
                            <Button onClick={onClose} shape="round" danger>
                                Cancel
                            </Button>
                            <Button htmlType="submit" shape="round">
                                Submit
                            </Button>
                        </div>
                    </Form>
                </div>
            </Drawer>
        </div>
    );
}

export default EditTestimonial;
