import React, { useEffect, useState } from "react";
import { Table, Checkbox, Pagination, message } from "antd";
import { useAuth } from "../../authentication/context/authContext";
import { FetchPincodeList } from "../../service/api_services";

function PincodeList({ selectedPincodes, setSelectedPincodes }) {
    const { token, logout } = useAuth();
    const [pinList, setPinList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [current, setCurrent] = useState(1);
    const [totalPage, setTotalPage] = useState(null);

    const onChange = (page) => {
        setCurrent(page);
        setIsLoading(true);
    };

    const ShowAllPincodeList = async () => {
        try {
            const res = await FetchPincodeList(token, current);
            if (res.status === 200) {
                setPinList(res.data.data.data);
                setTotalPage(res.data.data.totalPage);
                setIsLoading(true);
            } else if (res.data.code === 283) {
                message.error(res.data.message);
                logout();
            }
        } catch (error) {
            console.log(error);
            setIsLoading(true);
        }
    };

    useEffect(() => {
        ShowAllPincodeList();
    }, [current]);

    const handleCheckboxChange = (pincode) => {
        if (selectedPincodes.includes(pincode)) {
            setSelectedPincodes(selectedPincodes.filter((code) => code !== pincode));
        } else {
            setSelectedPincodes([...selectedPincodes, pincode]);
        }
    };

    const columns = [
        {
            title: "Select",
            dataIndex: "pincode",
            render: (pincode) => (
                <Checkbox
                    checked={selectedPincodes.includes(pincode)}
                    onChange={() => handleCheckboxChange(pincode)}
                />
            ),
        },
        {
            title: "Pincode",
            dataIndex: "pincode",
            key: "pincode",
        },
        {
            title: "Place",
            dataIndex: "area",
            key: "area",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
    ];

    return (
        <div>
            <Table
                columns={columns}
                dataSource={pinList}
                rowKey="pincode"
                loading={!isLoading}
                pagination={false}
            />
            <Pagination
                current={current}
                onChange={onChange}
                total={totalPage * 10}
                style={{ marginTop: "16px", textAlign: "right" }}
            />
        </div>
    );
}

export default PincodeList;
