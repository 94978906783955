import React, { useState } from "react";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Select,
  Upload,
  message,
} from "antd";
import "../../style/testimonial.css";
import { InsertNewTestimonial } from "../../service/api_services";
import { useAuth } from "../../authentication/context/authContext";
import DefaultImg from "../../assest/icon/imageIcon.png"


function AddTestimonial({ ShowAllTestimonialList }) {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(new FormData());
  const [form] = Form.useForm();
  const [uploader, setUploader] = useState(null);
  const mediaList = ["image", "video"];
  const [mediaType, setMediaType] = useState("image"); // Track selected media type
  const { token } = useAuth();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields();
    setFormData(new FormData());
    setUploader(null);
  };

  const onFinish = async (value) => {
    try {
      if (mediaType.toLowerCase() === "image" && value.logoFile?.file) {
        formData.append("testimonialMedia", value.logoFile.file);
      } else if (mediaType.toLowerCase() === "video" && value.videoFile?.file) {
        formData.append("testimonialMedia", value.videoFile.file);
      }
      formData.append("message", value.message);
      formData.append("testimonialMediaType", mediaType.toUpperCase());
      console.log("=======")
      for (let [key, value] of formData.entries()) {
        console.log(key, value);
      }

      await InsertNewTestimonial(token, formData).then((res) => {
        if (res.request.status === 201) {
          message.success(res.data.message);
          onClose();
          ShowAllTestimonialList();
        } else if (res.status === 200) {
          message.error(res.data.message);
        }
      });
    } catch (error) {
      console.log(error);
      message.error("Error while adding testimonial.");
    }
  };

  const handleImage = (info) => {
    setUploader(info.fileList);
  };


  return (
    <div className="modal_section">
      <Button
        shape="round"
        onClick={showDrawer}
      >
        Add
      </Button>      <Drawer
        title="Add Testimonial"
        placement="right"
        width={400}
        onClose={onClose}
        open={open}
      >
        <div className="add_category_form">
          <Form
            form={form}
            layout="vertical"
            name="add-testimonial"
            className="images"
            onFinish={onFinish}
          >
            <Col md={24}>
              <Form.Item
                name="mediaType"
                label="Media Type"
                rules={[{ required: true, message: "Please select a media type" }]}
              >
                <Select
                  placeholder="Select Media Type"
                  onChange={(value) => setMediaType(value)}
                >
                  {mediaList.map((option) => (
                    <Select.Option key={option} value={option}>
                      {option.charAt(0).toUpperCase() + option.slice(1)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                label="Message"
                name="message"
                rules={[{ required: true, message: "Please enter testimonial message" }]}
              >
                <Input placeholder="Enter Testimonial Message" />
              </Form.Item>
            </Col>

            {mediaType === "image" && (
              <Col md={24}>
                <Form.Item
                  name="logoFile"
                  label={
                    uploader?.length !== 1
                      ? "Choose Image"
                      : "If you want to choose another image, remove the existing image"
                  }
                >
                  <Upload
                    multiple={false}
                    listType="picture-circle"
                    beforeUpload={() => false} // Prevents automatic upload
                    accept="image/*" // Adjust to support only images
                    onChange={handleImage} // Handle image selection
                  >
                    {uploader?.length !== 1 ? (
                      <img src={DefaultImg} className="image_hold" alt="Upload Placeholder" />
                    ) : null}
                  </Upload>
                </Form.Item>
              </Col>

            )}

            {mediaType === "video" && (
              <Col md={24}>
                <Form.Item
                  name="videoFile"
                  label={
                    uploader?.length !== 1
                      ? "Choose Video"
                      : "If you want to choose another video, remove the existing one"
                  }
                >
                  <Upload
                    multiple={false}
                    listType="picture-circle"
                    beforeUpload={() => false} // Prevents automatic upload
                    accept="video/*" // Accept only video files
                    onChange={handleImage} // Handle video selection
                  >
                    {uploader?.length !== 1 ? (
                      <img src={DefaultImg} className="image_hold" alt="Default" />
                    ) : null}
                  </Upload>
                </Form.Item>
              </Col>


            )}

            <div className="model_Submit">
              <Button onClick={onClose} shape="round" danger>
                Cancel
              </Button>
              <Button htmlType="submit" shape="round">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </Drawer>
    </div>
  );
}

export default AddTestimonial;
