import LoginPage from "../authentication/login/LoginPage";
import RoleManagement from "../authentication/role/RoleManagement";
import OrderDetails from "../component/OrderManage/OrderDetails";
import OrderList from "../component/OrderManage/OrderList";
import BannerManage from "../component/banner/BannerManage";
import FaqList from "../component/cms/FAQ/FaqList";
import TermCondition from "../component/cms/TermCondition/TermCondition";
import PrivecyPolicy from "../component/cms/policy/PrivecyPolicy";
import MainLayout from "../component/dashboard/MainLayout";
import Dashboard from "../component/dashboard/dashboard-analitics/Dashboard";
import Cateogry from "../component/master/category/Cateogry";
import PincodeList from "../component/master/pincode/PincodeList";
import DiscountList from "../component/offerManane/DiscountList";
import ProfileDetails from "../component/profile/ProfileDetails";
import ServcieList from "../component/service/ServcieList";
import GeneralSetting from "../component/settings/GeneralSetting";
import UserList from "../component/user/UserList";
import DeliveryBoy from "../component/vendor/DeliveryPartner";
import DeliveryPartnerDetails from "../component/vendor/DeliveryPartnerDetails";
import WalletHistory from "../component/vendor/WalletHistory";
import TestimonialList from "../component/testimonial/testimonialList";
// Main routes object with child routes
const routes = [
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/dashboard",
    element: <MainLayout />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/dashboard/order",
        element: <OrderList />,
      },
      {
        path: "/dashboard/user",
        element: <UserList />,
      },
      {
        path: "/dashboard/banner",
        element: <BannerManage />,
      },
      {
        path: "/dashboard/discount",
        element: <DiscountList />,
      },
      {
        path: "/dashboard/profile",
        element: <ProfileDetails />,
      },
      {
        path: "/dashboard/vendor-list",
        element: <DeliveryBoy />,
      },
      {
        path: "/dashboard/vendor-details/:id",
        element: <DeliveryPartnerDetails />,
      },
      {
        path: "/dashboard/vendor-wallet/:id",
        element: <WalletHistory />,
      },
      {
        path: "/dashboard/category",
        element: <Cateogry />,
      },

      {
        path: "/dashboard/service",
        element: <ServcieList />,
      },
      {
        path: "/dashboard/pincode",
        element: <PincodeList />,
      },
      {
        path: "/dashboard/orders",
        element: <OrderList />,
      },
      {
        path: "/dashboard/term-condition",
        element: <TermCondition />,
      },
      {
        path: "/dashboard/privecy-policy",
        element: <PrivecyPolicy />,
      },
      {
        path: "/dashboard/faq",
        element: <FaqList />,
      },
      {
        path: "/dashboard/role",
        element: <RoleManagement />,
      },
      {
        path: "/dashboard/setting",
        element: <GeneralSetting />,
      },
      {
        path: "/dashboard/order-details/:id",
        element: <OrderDetails />,
      },
      {
        path: "/dashboard/testimonial",
        element: <TestimonialList />,
      },
    ],
  },
];

export default routes;
