import { Avatar, Descriptions, Image, Tag } from 'antd'
import React from 'react'
import { FaRegUserCircle } from "react-icons/fa";
import { Icons } from '../../common/icons';

function PersonDetails({ vendorInfo }) {
    console.warn("vendorInfo", vendorInfo)


    return (
        <div className='personal_details'>
            <Descriptions bordered layout="vertical">
                <Descriptions.Item label="Vendor Image">
                    {vendorInfo && vendorInfo?.profilePic != null ?
                        <Image src={vendorInfo?.profilePic} width={40} height={40} style={{ objectFit: "cover", borderRadius: "100%" }} /> : <Avatar
                            size={40}
                            style={{
                                backgroundColor: '#f8229f',
                                cursor: "pointer"
                            }}
                            icon={<span className="avtar_icon">{Icons.User}</span>}
                        />}
                </Descriptions.Item>
                <Descriptions.Item label="Vendor Name">
                    {vendorInfo && vendorInfo?.fullName}
                </Descriptions.Item>
                <Descriptions.Item label="Vendor Number">
                    {vendorInfo && vendorInfo?.mobileNo}
                </Descriptions.Item>
                <Descriptions.Item label="Vendor Email">
                    {vendorInfo && vendorInfo?.email}
                </Descriptions.Item>
                <Descriptions.Item label="AadharCard No">
                    {vendorInfo && vendorInfo?.aadharCardNo != null ? vendorInfo?.aadharCardNo : <span>N/A</span>}
                </Descriptions.Item>
                <Descriptions.Item label="panCard No">
                    {vendorInfo && vendorInfo?.panCardNo != null ? vendorInfo?.panCardNo : <span>N/A</span>}
                </Descriptions.Item>
                <Descriptions.Item label="Document">
                    {vendorInfo && vendorInfo?.isDoucmentUploaded == true ? <Tag color="green">Uploaded</Tag> : <Tag color="red">Not Upload</Tag>}
                </Descriptions.Item>
                <Descriptions.Item label="Approvel Status">
                    {vendorInfo && vendorInfo?.isDocumentVerified == false ? <Tag color="red">Rejecet</Tag> : vendorInfo?.isDocumentVerified == true ? <Tag color="green">Approved</Tag> : <Tag color="blue">Pending</Tag>}
                </Descriptions.Item>

                <Descriptions.Item label="DOB">
                    {new Date(vendorInfo?.dob).toLocaleDateString()}
                </Descriptions.Item>


                <Descriptions.Item label="State ">
                    {vendorInfo && vendorInfo?.state != null ? vendorInfo?.state : <span>N/A</span>}

                </Descriptions.Item>
                <Descriptions.Item label="Pincode ">
                    {vendorInfo && vendorInfo?.pinCode != null ? vendorInfo?.pinCode : <span>N/A</span>}

                </Descriptions.Item>
                <Descriptions.Item label="Full Address ">
                    {vendorInfo && vendorInfo?.address != null ? vendorInfo?.address : <span>N/A</span>}

                </Descriptions.Item>
            </Descriptions>



            <Descriptions bordered layout="vertical" title="Account Details">

                <Descriptions.Item label="Account holder Name">
                    {vendorInfo && vendorInfo?.holderName != null ? vendorInfo?.holderName : <span>N/A</span>}
                </Descriptions.Item>
                <Descriptions.Item label=" Account Number">
                    {vendorInfo &&vendorInfo?.accountNumber != null ? vendorInfo?.accountNumber : <span>N/A</span>}
                </Descriptions.Item>
                <Descriptions.Item label="IFSC Code ">
                    {vendorInfo &&vendorInfo?.ifscCode != null ? vendorInfo?.ifscCode : <span>N/A</span>}
                </Descriptions.Item>




            </Descriptions>
        </div>
    )
}

export default PersonDetails