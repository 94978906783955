import React, { useEffect, useState, useCallback } from "react";
import { Avatar, Breadcrumb, Button, Card, Form, Input, Image, Pagination, Popconfirm, Space, Select, Table, Tag, Typography, message } from "antd";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { MdOutlineDelete } from "react-icons/md";
import EditTestimonial from "./Edittestimonial";
import { FetchTestimonialList } from "../../service/api_services";
import { useAuth } from "../../authentication/context/authContext";
import { SearchOutlined } from '@ant-design/icons';
import AddTestimonial from "./Addtestimonial";
import _debounce from 'lodash/debounce';
import { CategoryHook } from "../../pages/CustomHooks";
import { deleteTestimonialFromList } from "../../service/api_services";
import ShowDescription from "../service/ShowDescription";

const { Title } = Typography;

const TestimonialList = () => {
    const location = useLocation();
    const [form] = Form.useForm();
    const { token, logout } = useAuth();
    const [testimonialList, setTestimonialList] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Start with loading state as true
    const [current, setCurrent] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [editingTestimonialId, setEditingTestimonialId] = useState(null); // Track testimonial being edited
    const [searchInput, setSearchInput] = useState("");
    const { defaultId, categoryList, setDefaultId } = CategoryHook(token)

    // Fetching the testimonial list
    const ShowAllTestimonialList = async () => {
        setIsLoading(true); // Set loading to true before fetching
        try {
            const res = await FetchTestimonialList(token, current, searchInput);
            console.log("Testimonial List:", res.data.data);
            if (res.request.status === 200) {
                setTestimonialList(res.data.data); // Assuming res.data.data is an array of testimonials
                setTotalPage(res.data.totalPage);
            } else if (res.data.code === 283) {
                message.error(res.data.message);
                logout();
            }
        } catch (error) {
            console.log("Error fetching testimonials:", error.message);
            message.error("Error fetching testimonials");
        } finally {
            setIsLoading(true); // Set loading to false after fetching is done
        }
    };

    // Delete a testimonial
    const deleteTesttimonial = async (id) => {
        try {
            let body = {
                testimonialId: id,
            };
            await deleteTestimonialFromList(body, token)
                .then((res) => {
                    console.log("delete service ", res);
                    if (res.status == 201) {
                        message.success(res.data.message);
                        ShowAllTestimonialList();
                    }
                })
                .catch((err) => {
                    console.log(err.response.msg);
                });
        } catch (error) {
            console.log(error);
            setIsLoading(true);
        }
    };

    const handleCategoryChange = (value) => {
        setDefaultId(value);
        console.log("value", value);
    };

    const handleSearchChange = useCallback(_debounce((value) => {
        setSearchInput(value);
    }, 1000), []);

    // Pagination Change Handler
    const onChange = (page) => {
        setCurrent(page);
    };

    // Effect to fetch the testimonials whenever `current` or `searchInput` changes
    useEffect(() => {
        ShowAllTestimonialList();
    }, [searchInput, current]);

    // Columns for the table
    const columns = [
        {
            title: "Media",
            dataIndex: "testimonialMediaUrl",
            key: "testimonialMediaUrl",
            render: (_, { testimonialMediaType, testimonialMediaUrl }) =>
                testimonialMediaType === "IMAGE" ? (
                    <Image
                        src={testimonialMediaUrl}
                        width={40}
                        height={40}
                        style={{ objectFit: "cover", borderRadius: "8px" }}
                    />
                ) : (
                    <video width={80} height={40} controls>
                        <source src={testimonialMediaUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                ),
        },
        {
            title: "Message",
            dataIndex: "message",
            key: "message",
            render: (_, { message }) => (<Space><span>{message?.substr(0, 20)}...</span> <ShowDescription descriptions={[message]} /></Space>),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status) => (
                <Tag color={status === "ACTIVE" ? "green" : "red"}>{status}</Tag>
            ),
        },
        {
            title: "Action",
            key: "_id",
            render: (_, { _id }) => (
                <div className="vendor_action">
                    <EditTestimonial testimonialId={_id} ShowAllTestimonialList={ShowAllTestimonialList} />
                    <Popconfirm
                        title="Delete the Testimonial"
                        description="Are you sure to delete this Testimonial?"
                        onConfirm={() => deleteTesttimonial(_id)}
                        onCancel={() => message.error("Deletion cancelled")}
                        okText="Yes"
                        cancelText="No"
                    >
                        <MdOutlineDelete className="delete_icon" />
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <section className="main_section">
            <Breadcrumb
                items={[
                    { title: "Dashboard" },
                    { title: location.pathname },
                ]}
            />
            <div className="content_title">
                <div className="content_head">
                    <div className="header_filter">

                        <div className="content_title">
                            <Title level={4}>All Testimonials List</Title>
                        </div>

                    </div>
                    <div className="content_add">
                        <div className="select_category">
                            <Space>
                                <Form.Item name="Search Testimonial">
                                    <Input
                                        style={{ width: '200px' }}
                                        allowClear
                                        placeholder="Search Testimonial"
                                        suffix={<SearchOutlined />}
                                        onChange={(e) => handleSearchChange(e.target.value)}
                                    />
                                </Form.Item>
                                <Form
                                    form={form}
                                >

                                    <Form.Item
                                        name="categoryId"

                                    >
                                        <Select
                                            showSearch

                                            placeholder="Select Category "
                                            optionFilterProp="children"

                                            onChange={handleCategoryChange}
                                        >
                                            {["image", "video"]?.map((option) => (
                                                <Select.Option
                                                    key={option.categoryName}
                                                    level={option.categoryName}
                                                    value={option._id}
                                                >
                                                    {option}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Form>
                                <Form.Item name="add">
                                    <AddTestimonial ShowAllTestimonialList={ShowAllTestimonialList} />
                                </Form.Item>
                            </Space>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="shoo_recent_order">
                        {!isLoading ? <div className="loader_main"> <span class="loader"></span></div> :
                            <Table columns={columns} dataSource={testimonialList} scroll={{ x: true }}
                                pagination={false}
                                footer={() => <div className="pagination">
                                    <Pagination current={current} onChange={onChange} total={1 * 10} />
                                </div>}
                            />}
                    </div>
                </div>


            </div>
        </section>
    );
};

export default TestimonialList;
